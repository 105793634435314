<template>
  <div>
    <div class="h-screen w-full flex flex-col justify-center items-center">
      <h1 class="text-8xl mb-8">Service Packs</h1>
      <router-link to="/"
        ><twelve-card border>Back Home</twelve-card></router-link
      >
    </div>
    <div
      id="remote"
      class="h-screen w-full flex flex-col justify-center items-center"
    >
      <h1 class="text-8xl mb-8">Remote Service</h1>
      <router-link to="/"
        ><twelve-card border>Back Home</twelve-card></router-link
      >
    </div>
  </div>
</template>
